@use "../abstracts/" as *;

.language-container {
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;

  select {
    background-color: $modal-light-background-color-overlay;
    border: 1px solid #000000;
    color: #000000;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1rem;

  }
}
