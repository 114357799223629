@use '../abstracts/' as *;
@font-face {
  font-family: 'FriendsCapitaine'; /* Define a unique name for the font */
  src: url('../../assets/Fonts/FriendsCapitaine-Regular.otf') format('opentype'); /* Specify the path to your font file */
}

.homePage {
  h2 {
    color: #000000;
    font-size: clamp(1rem, 1.9vw, 2rem);
    text-align: center;

    @include mq(tablet) {
      font-size: $text-h2-size-tablet;
    }

    @include mq(mobile) {
      font-size: $text-h2-size-tablet;
      margin-top: 0.3rem;
    }
  }

  p {
    color: #000000;
    font-size: $text-size;
    text-align: center;
    padding: 0 clamp(1rem, 4vw, 6rem);

    @include mq(tablet) {
      font-size: $text-size-tablet;
    }

    @include mq(mobile) {
      font-size: $text-size-mobile;
    }
  }

  .homePage-container {
    @include centerItem;
    min-width: 100vw;
    height: 85vh;
    background: url("../../assets/images/2024/Front_nologos.svg");
    background-size: cover;
    background-position-y: 35%;
    background-repeat: no-repeat;
    padding-right: clamp(0.5rem, 14vw, 15rem);
    padding-left: clamp(0.5rem, 14vw, 15rem);

    @include mq(tablet) {
      background: no-repeat center url("../../assets/images/2024/Front_nologos.svg");
      background-position-y: 75%;
      padding-right: clamp(0.5rem, 10vw, 15rem);
      padding-left: clamp(0.5rem, 10vw, 15rem);
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include mq(mobile) {
      background-image: no-repeat center/70% url("../../assets/images/2024/Front_nologos.svg");
      background-position-y: 15%;
      background-size: cover;
      background-repeat: no-repeat;
      height: 45rem;
    }

    .homePage-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: rgba($light-background-color-overlay, 0.8);
      border-radius: 20px;
      padding: 3% 0;

      @include mq(tablet) {
        padding: 0;
      }

      @include mq(mobile) {
        padding: 5% 0;
      }
    }

    .homePage-button {
      background: transparent;
      border: solid #000000;
      border-radius: 5px;
      color: #000000;
      font-weight: bold;
      cursor: pointer;
      margin: 1.2rem 0;

      @include mq(mobile) {
        margin: 1rem 0;
      }

      p {
        font-weight: bold;
        letter-spacing: 5px;
        font-size: $button-text-size;
        padding: 1rem;

        @include mq(mobile) {
          font-size: $text-size-mobile;
        }
      }
    }

    .homePage-text {
      p {
        margin: 1.1rem 0;
      }
    }

    .homePage-snallkalendern {
      width: clamp(230px, 30vw, 500px);

      @include mq(tablet) {
        width: clamp(230px, 50vw, 500px);
      }
    }
  }

  footer {
    position: relative;
    padding: 0 2em;
    max-width: 100vw;
    max-height: 15vh;
  }

  .footer-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.5%;

    @include mq(tablet) {
      justify-content: center;
      margin-top: 3%;
    }

    @include mq(mobile) {
      justify-content: space-evenly;
    }

    img {
      &:nth-of-type(2) {

        @include mq(tablet) {
          margin-left: 0;
        }
      }

      &:nth-of-type(5) {
        margin-left: auto;

        @include mq(tablet) {
          margin-left: 0;
        }
      }
    }
  }

  .column-line {
    border-left: 1px solid grey;
    height: 60px;
    margin: 0 3rem;

    @include mq(mobile) {
      margin: 0.5rem;
    }
  }



  .copyright-text {
    font-family: 'FriendsCapitaine'; /* Use the font family name defined in step 1 */
    position: absolute;
    bottom: -50px; /* Adjust this value to control the distance from the bottom */
    left: -25px; /* Adjust this value to control the distance from the left */
    color: #000000; /* Your desired text color */
    font-size: 14px; /* Your desired font size */

    @include mq(tablet) {
      width: clamp(1rem, 16vw, 10rem);
      position: absolute;
      top: 30%;
      left: 1%;
    }

    @include mq(mobile) {
      width: clamp(4rem, 10vw, 10rem);
      position: absolute;
      top: 185%;
      left: 2%;
    }
  }

  .logo-style {
    width: clamp(1rem, 16vw, 5rem);

    @include mq(tablet) {
      width: clamp(1rem, 16vw, 10rem);
    }

    @include mq(mobile) {
      width: clamp(4rem, 10vw, 10rem);
    }
  }

  .mrs-mighetto-style {
    width: clamp(1rem, 14vw, 13rem);
    opacity: 0.6;

    @include mq(tablet) {
      width: clamp(1rem, 17vw, 11rem);
    }

    @include mq(mobile) {
      width: clamp(5.5rem, 14vw, 9rem);
    }
  }

}
