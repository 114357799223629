@use "../abstracts/" as *;

.calendarPage-container {
  width: 100%;
  height: 100vh;

  .calendarPage-background {
    background-image: url("../../assets/images/2024/Open_calendar_nomarks.svg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 95%;

    @include mq(web) {
      width: 1800px;
      min-height: 100vh;
      background-size: cover;
      background-position-y: 100%;
      .card {
        min-width: 13vw;
      }
    }
    @include mq(tablet) {
      width: 1800px;
      background-position-y: 100%;
      .card {
        min-width: 13vw;
      }

    }
    @include mq(mobile) {
      width: 1450px;
      background-size: cover;
      background-position-y: 100%;
      .card {
        min-width: 20vw;
      }
    }
  }

  .calendar {
    display: grid;
    grid-template-columns: repeat(8, auto);
    overflow: hidden;

    .calendarCard {
      position: relative;
      margin: 2%;

      &:first-of-type {
        top: 18%;
        left: 20%;
      }
      &:nth-of-type(2) {
        top: 30%;
        left: -10%;
      }
      &:nth-of-type(3) {
        top: 5%;
        left: -55%;
      }
      &:nth-of-type(4) {
        top: 25%;
        left: -110%;
      }
      &:nth-of-type(5) {
        top: 10%;
        left: -85%;
      }
      &:nth-of-type(6) {
        top: 5%;
        left: -60%;
      }
      &:nth-of-type(7) {
        top: 5%;
        left: -25%;
      }
      &:nth-of-type(8) {
        top: 10%;
        left: -5%;
      }
      &:nth-of-type(9) {
        top: 30%;
        left: 35%;
      }
      &:nth-of-type(10) {
        top: -25%;
        left: 60%;
      }
      &:nth-of-type(11) {
        top: -5%;
        left: 20%;
      }
      &:nth-of-type(12) {
        top: -35%;
        left: 0%;
      }
      &:nth-of-type(13) {
        top: 30%;
        left: -75%;
      }
      &:nth-of-type(14) {
        top: 25%;
        left: -40%;
      }
      &:nth-of-type(15) {
        top: 25%;
        left: 0%;
      }
      &:nth-of-type(16) {
        top: -5%;
        left: -30%;
      }
      &:nth-of-type(17) {
        top: 10%;
        left: 85%;
      }
      &:nth-of-type(18) {
        top: 15%;
        left: 60%;
      }
      &:nth-of-type(19) {
        top: -20%;
        left: 100%;
      }
      &:nth-of-type(20) {
        top: 25%;
        left: 130%;
      }
      &:nth-of-type(21) {
        top: -20%;
        left: 175%;
      }
      &:nth-of-type(22) {
        top: -15%;
        left: 175%;
      }
      &:nth-of-type(23) {
        top: 5%;
        left: 125%;
      }
      &:nth-of-type(24) {
        top: -80%;
        left: 40%;
      }
    }
  }
}
