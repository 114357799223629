@use "../abstracts/" as *;

.card {
  background-size: cover;
  @include centerItem;
  position: relative;
  margin-top: 40px;
  //border: $calendarWindow-border-color;
  //border-radius: 15%;
  text-align: center;
  cursor: pointer;

  h3 {
    color: $calendarWindow-text-color;
    font-family: FriendsCapitaine-SemiBold, 'Friends Capitaine';
    font-size: 2rem;
    font-weight: 600;
  }
}
